import ClickToCall from '@babylon/ui-kit-common/components/others/click-to-call/index';
import type { FC } from 'react';

import {
	componentMobileMapping,
	renderBreadcrumbs,
	renderLinks,
	renderTextHtmlCollapse,
} from '@/pages/circuits/common/mobile';
import { renderBannerSearcher } from '@/pages/circuits/common/mobile/renders/mobile';
import { renderComponentsWithOrder } from '@/utils/render/components';

import { dataConverter } from '../../../common/mobile/converter';
import type { MainHomeMobileProps } from '../../../common/types';
import * as S from './styled';

const LAYOUT_LANDING_ORDER_MOBILE = [
	'--LAYOUT_LANDING_ORDER_MOBILE--',
	'config',
	'mBannerSearcher',
	'mBreadcrumbs',
	'mCardCampaignList',
	'mTabsProductCir',
	'mCarousel',
	'mTravelSafe',
	'mFeatures',
	'mMasonry',
	'mMansory',
	'mBannerCarousel',
	'mBlog',
	'mProductCardT2ListGroup',
	'mTextScroll',
	'mTabsSeoLink',
];

export const MainHomeLandingMobile: FC<MainHomeMobileProps> = propsMain => {
	const { props } = propsMain;
	const { config = {} } = props;
	const { order } = config;

	const rendercomponentsOrder = order?.length ? order : LAYOUT_LANDING_ORDER_MOBILE;
	const { data } = dataConverter(props);

	return (
		<S.Main data-testid='MainHomeLanding'>
			{renderBannerSearcher(data.mBannerSearcher)}
			{renderBreadcrumbs(data.mBreadcrumbs)}
			{renderComponentsWithOrder(rendercomponentsOrder, data, componentMobileMapping)}
			{renderTextHtmlCollapse(data.textCutCollapseBox)}
			{renderLinks(data.mTabsSeoLink)}
			{data.clickToCall && <ClickToCall {...data.clickToCall} />}
		</S.Main>
	);
};

export default MainHomeLandingMobile;
